<template>
    <BT-Blade-Items
        :addTo="{ name: 'new-clients' }"
        addBladeName="client"
        bladeName="clients"
        :bladesData="bladesData"
        canAdd
        :canCreate="false"
        :headers="[
            { text: 'Company Name', value: 'buyer.companyName', title: 1, searchable: true },
            { text: 'Billing', value: 'courierOrderBillTrigger' },
            { text: 'Request Approval', value: 'courierRequestApprovalTrigger' }]"
        :itemProperties="['ID','BuyerID','Buyer','CourierOrderBillTrigger','CourierRequestApprovalTrigger']"
        navigation="clients"
        canShowInactive
        title="Clients"
        useServerPagination
        :defaultBladeWidth="350">
        <template slot="settings">
            <v-list-item :to="{ name: 'client-requests' }">
                <v-list-item-icon>
                    <v-icon small>mdi-account-question</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>New Requests</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <BT-Entity
                v-if="$canView('courier-settings')"
                ignoreID
                inline
                navigation="courier-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-divider />
                    <v-subheader>Settings</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Publically Visible</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch v-model="item.isPublicCourier" color="success" @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Public Description</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-edit-dialog>
                                <v-icon small :class="item.publicDescription == null || item.publicDescription.length == 0 ? '' : 'success--text'">mdi-book-open-variant</v-icon>
                                <template v-slot:input>
                                    <v-textarea
                                        clearable
                                        outlined
                                        label="Public Description"
                                        v-model="item.publicDescription"
                                        @change="save" />
                                </template>
                            </v-edit-dialog>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Open To New Clients</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch v-model="item.isOpenToAgreementRequests" color="success" @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Auto Accept New Clients</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch v-model="item.autoApproveAgreementRequests" color="success" @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Default Template For New Clients</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <BT-Menu-Select
                                icon="mdi-account-box"
                                itemText="templateName"
                                itemValue="id"
                                navigation="client-templates"
                                v-model="item.defaultTemplateID"
                                @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </BT-Entity>
        </template>
        <template v-slot:itemActions="{ item }">
            <Customer-Link
                v-if="item.buyer != null && item.buyer.isManagedByAnotherAccount"
                :agreement="item"
                :managedAccountID="item != null ? item.buyer.id : null" />
            <v-icon 
                v-else
                small
                title="Live Account" />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Clients-Blade',
    components: {
        CustomerLink: () => import('~home/customers/Customer-Link.vue'),
        BTMenuSelect: () => import('~components/BT-Menu-Select.vue')
    },
    props: {
        bladesData: null
    }
}
</script>